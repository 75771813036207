.App {
  text-align: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


#seperator {
  border: aqua solid 2px;   /*The lines in between sections*/
}

/*first page*/
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#headText {
  font-size: 3rem;
}

@media only screen and (max-width: 1200px) {
  #headText {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 600px) {
  #headText {
    font-size: 2rem;
  }
}

#button {
  width: 180px;
  padding: 10px;
  background-color: transparent;
  border-color: white;
  color: white;
  font-size: 1.5rem;
  transition: 0.3s;
}

#button:hover {
  background-color: white;
  color: black;
  border-color: black;
}

#button:hover span {  
  display: none;
}
                            /*changing the text in the button so the arrow points down*/
#button:hover:before { 
  content: 'Learn More ↓';
}
/*end of first page*/

/*about section*/
#information {
  width: 100%;
  background-color: #282c34;
  display: flex;
  justify-content: center;
}

#about {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 1200px;
}

#aboutMe {
  width: 40%;
  padding: 10px;
}

#aboutMe h2 {
  font-size: 2.5rem;
}

#aboutMe p {
  font-size: 1.5rem;
}

#languages {
  width: 40%;
}

#languages h2 {
  font-size: 4.5rem;
}

#languages li:hover {
  font-size: 4rem;
  color: aqua;
  letter-spacing: 5px;
}

#languages ul {
  list-style: none;
  font-size: 3rem;
}

#languages li {
  transition: 0.3s;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 58px;
}

@media only screen and (max-width: 1200px) {  /*styling for tablet sizes*/
  #about {
    width: 600px;
    flex-direction: column;
    justify-content: center;
  }

  #aboutMe {
    width: 100%
  }

  #languages {
    width: 100%;
  }
}

@media only screen and (max-width: 600px){   /*styling for phone sizes */
  #about {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
  }

  #about {
    width: 80%;
  }

  #languages {
    display: none;
  }
}
/*end of about section*/

/*time line of previous work*/
#previousWork {
  background-color: #282c34;
  width: 100%;
}

#timeline {
  box-sizing: border-box;
  background-color: #282c34;
  position: relative;
  margin: 0 auto;
  padding: 40px 0;
  width: 1200px;
}

#timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

.container {
  padding: 10px 40px;
  position: relative;
  width: 40%;
}

.container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: #000000;
  border: 4px solid aqua;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

.left {
  left: 3.3%;  /*this solved the left box's positioning problem*/
}

.right {
  left: 50%;
}

.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

.right::after {
  left: -16px;
}

.content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
}

@media only screen and (max-width: 1200px) { /*styling for tablet sizes*/
  #timeline {
    width: 600px;
  }

  .container {
    width: 50%;
  }

  .left {
    left: -13.415%;
  }
}

@media screen and (max-width: 600px) {
    #timeline {
      width: 400px;
    }

    .container {
      width: 45%;
    }

    .left {
      left: -14.8%;
    }
  }
/*end of timeline styling*/

/*Contact Section*/
#contact {
  background-color: #282c34;
  color: white;
  padding: 40px 0;
}

form {
  border: 2px solid aqua;
  width: 40%;
  display: inline-block;
  padding: 20px;
}

input {
  width: 80%;
  padding: 10px;
  margin: 15px 0;
  border: 2px solid aqua;
}

#message {
  height: 8vh;
  box-sizing: border-box;
  width: 83%;
  border: 2px solid aqua;
  margin: 15px 0;
}

#submit {
  padding: 20px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-weight: bold;
  font-size: 1rem;
  transition: 0.3s;
}

#submit:hover {
  background-color: white;
  color: black;
}